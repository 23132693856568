const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');
const Image = require('nordic/image');

const ICON_ID = 'medal';

const namespace = 'ui-pdp-icon ui-pdp-icon--medal';

const IconMedal = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="medal.svg" alt="" />
  </div>
);

IconMedal.propTypes = {
  className: string,
};

IconMedal.defaultProps = {
  className: null,
};

module.exports = IconMedal;
exports = module.exports;
exports.ICON_ID = ICON_ID;
